export default {
  home: {
    RebateAccountNumber: 'Rebate account number',
    totalCommission: 'Total commission',
    APPLY_FOR_A_REBATE: 'APPLY FOR A REBATE',
    availableBalance: 'Available balance',
    WITHDRAW_REBATE: 'WITHDRAW',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFER',
    TOTAL_VOLUME: 'Total volume',
    NET_FUNDING: 'Net funding',
    DEPOSITS: 'Deposits',
    WITHDRAW: 'Withdraw',
    OPEND_ACCOUNT: 'Opened account',
    PERFORMANCE: 'PERFORMANCE',
    MOST_TRADED_INSTRUMENTS: 'MOST TRADED INSTRUMENTS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'TOP PERFORMING ACCOUNTS FOR THIS MONTH',
    RECENTLY_OPENED_ACCOUNTS: 'RECENTLY OPENED ACCOUNTS',
    ACCOUNT_MANAGER: 'ACCOUNT MANAGER',
    Account: 'Account',
    Name: 'Name',
    Volume: 'Volume',
    Rebate: 'Rebate',
    Balance: 'Balance',
    ContactClient: 'Contact Client',
    applyRebateSuccessTip:
      'Congratulations, we have received your rebate request. | {amount} will be transferred to the rebate Account {account} shortly. | Once processed, you will be able to withdraw or transfer your funds.',
    HOVER: 'The previous day’s rebates are calculated and processed between 09:00 - 11:00 AEST daily.',
    blacklist: 'This account is unable to make a rebate at the moment, please contact your account manager.',
  },
  rate: {
    rate: 'Exchanges Rates({oldCurrency}/{newCurrency}): ',
    result: {
      rateError: 'Fetch {oldCurrency} to {newCurrency} rate failed.',
      channelError: 'Fetch payment channel failed.',
    },
  },
  liveChat: { header: 'Live Chat' },
  contactUs: {
    tip: 'If you have questions or would like more information, please get in touch with us through one of the contact details below.<br> We will endeavour to respond to all queries within 24 hours.',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ACCOUNT APPLICATION PROCESSING',
      id: 'ID is',
      poa: 'Address is',
      both: 'ID and Proof of address are',
      content: 'Your Proof of {doc} currently being processed and will be finalized shortly.',
    },
  },
  identityProof: {
    home: {
      header1: 'Upload your documents',
      content1: 'Upload your documents to complete your application.',
    },
    popup: {
      header:
        'Hi {name}, before your live account can be finalised you are required to upload the following document for verification.',
      addressProofSpan:
        '<strong>\n      <b>Confirm Address</b>\n      </strong>\n      <br />\n      <I>\n        Submit <b>one</b> of the documents below\n        <br /><br />\n      </I>\n      - Copy of a recent Utility Bill </br>(no older than 3 months)\n      <br />\n      <b class="or">or</b><br />\n      - Copy of a recent Bank Statement </br> (no older than 3 months)\n      <br />\n      <br />\n      <b>IMPORTANT !</b> Name on document MUST clearly show your full name and current address.\n      <br />',
    },
    reasonType: {
      4: 'Incomplete Documents',
      5: 'Uncleared Documents',
      6: 'Colourful Copy Required',
      8: 'Account Opening Name Mismatch',
      9: 'Address Proof Issue Date Not Qualified',
    },
    reason: 'Your documentation was unable to be verified,<br> Reason: {reason}. Please try again.',
    success:
      'Thank you. We have successfully received your documents and are currently processing them for verification.',
  },
  fileUploader: {
    supportedFormat: 'Supported file types: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Supported Files Type: {files}',
    maxSize: 'Maximum upload file size: {maxSize}MB',
    maxFileNum: 'Maximum file number: {maxFileNum}',
    failedFileExtension: 'Please upload the correct file type',
  },
  ibAccounts: {
    ibAccount: 'IB Account',
    subIbs: 'Sub IB Account',
    tradingList: 'Trading List',
    OpeningList: 'Opening List',
    closeList: 'Close List',
    tradingListColumn: {
      ticket: 'TicketID',
      openTime: 'Open Time',
      type: 'Type',
      volume: 'Volume',
      item: 'Item',
      openPrice: 'Open Price',
      closeTime: 'Close Time',
      closePrice: 'Close Price',
      commission: 'Commission',
      profit: 'Profit',
    },
  },
  withdraw: {
    banklist: {
      agriculturalbankofchina: 'Agricultural Bank of China',
      bankofbeijing: 'Bank of Beijing',
      bankofchina: 'Bank of China',
      bankofcommunications: 'Bank Of Communications',
      bankofdongguan: 'Bank of Dongguan',
      bankofguangzhou: 'Bank of Guangzhou',
      bankofhangzhou: 'Bank Of HangZhou',
      bankoflanzhoucoltd: 'BANK OF LANZHOU CO.,LTD',
      bankofningbo: 'Bank of Ningbo',
      bankofshanghai: 'Bank of Shanghai',
      chinabohaibank: 'China BoHai Bank',
      chinaciticbank: 'China Citic Bank',
      chinaconstructionbank: 'China Construction Bank',
      chinaeverbrightbank: 'China Everbright Bank',
      chinaindustrialbank: 'China Industrial Bank',
      chinamerchantsbank: 'China Merchants Bank',
      chinaminshengbank: 'China Minsheng Bank',
      chinazheshangbank: 'China Zheshang Bank',
      guandongdevelopmentbank: 'Guandong Development Bank',
      huaxiabank: 'HuaXia Bank',
      industrialandcommercialbankofchina: 'Industrial and Commercial Bank of China',
      jiangsunantongruralcommercialbank: 'Jiangsu Nantong Rural Commercial Bank',
      pinganbank: 'Ping An Bank',
      postalsavingsbankofchina: 'Postal Savings Bank Of China',
      shanghaipudongdevelopmentbank: 'Shanghai Pudong Development Bank',
      shanxiruralcreditcooperative: 'Shanxi Rural Credit Cooperative',
      shenzhendevelopbank: 'Shenzhen Develop Bank',
      wenlingruralcommercialbank: 'Wenling Rural Commercial Bank',
      zhejiangyilururalcommercialbank: 'Zhejiang Yilu Rural Commercial Bank',
    },
    localdepositor: {
      depositor: 'Local Depositor',
      tip: 'For withdrawal rates, please contact your local depositors.',
    },
    emailFormat: 'Please input correct email address',
    default: {
      withdrawlFeeAlert:
        'For withdrawals via Unionpay if the withdrawal amount less than 100 USD, you will be charged a fee of 20 USD.',
      selectCC: 'Select Credit Card',
      selectBA: 'Select Bank Account',
      anotherCC: 'Enter credit card manually',
      anotherBA: 'Add bank account',
      remember: 'Remember my account',
    },
    transfer: {
      info: '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      note: 'In order to process your withdrawal request, please provide your passport, for company accounts, please provide invoice to {email}',
      label: {
        upload: 'Please upload a copy of your bank statement (current to the last 3 months)',
      },
      formValidation: {
        upload: 'Please upload a bank statement',
        digits11: 'Please enter 11 digits',
        digitsAny: 'Please enter {number} digits',
        digitsAnytoAny: 'Please enter between {number} digits to {number2} digits',
        alphanumericAnytoAny: 'Please enter between {number} to {number2} alphanumeric',
      },
    },
    neteller: {
      field: { email: 'Neteller Email' },
      formValidation: { emailReq: 'Neteller Email is required' },
    },
    ebuy: {
      field: { email: 'Ebuy Email' },
      formValidation: { emailReq: 'Ebuy Email is required' },
    },
    bitcoin: {
      field: { address: 'Bitcoin Wallet Address' },
      formValidation: {
        addressReq: 'Bitcoin Wallet Address is required',
        addressFormat: 'Incorrect Bitcoin wallet address format',
        addressLength: 'Bitcoin wallet address must be between 26 and 42 characters',
      },
    },
    eth: {
      field: { address: 'ETH Wallet Address' },
      formValidation: { addressReq: 'ETH Wallet Address is required' },
    },
    usdc: {
      field: { address: 'USDC (ERC-20) Wallet Address' },
      formValidation: { addressReq: 'USDC (ERC-20) Wallet Address is required' },
    },
    usdt: {
      field: { address: 'USDT Wallet Address', chain: 'Chain' },
      formValidation: {
        addressReq: 'USDT Wallet Address is required',
        chain: 'Chain is required',
        walletFormatIncorrect: 'Incorrect {type} wallet address format',
      },
      note: 'The current gas fee of ERC20 is significantly higher than usual and as a result there may be a lengthy delay in the processing of your transaction. Alternatively, you are able to withdraw funds via TRC20 by providing the coresponding wallet address, or you can elect to pay the gas fee to speed up your ERC20 withdrawal.',
    },
    alipay: {
      field: { accountName: 'Alipay Account Holder Name', email: 'Alipay Email' },
    },
    verificationCodeFailed: 'The verification code is valid within 3 minutes, please try again later',
    sendVerCode: 'SEND CODE',
    sendVerEmail:
      'Verification code has been sent to {sendEmail}. Please contact {mail} if you need further assistance.',
    enterVerCode: 'Enter Verification Code',
    REBATE_WITHDRAW_REQUEST: 'REBATE WITHDRAW REQUEST',
    PleaseSelectDesiredWithdrawMethod: 'Please select desired withdraw method',
    bankCode: 'Bank Code',
    bankBranchCode: 'Branch Code',
    bankAccountCurrency: 'Bank Account Currency',
    BankName: 'Bank Name',
    BankAccountsName: 'Bank Account Name',
    FasaPayAccountName: 'FasaPay Account Name',
    AustralianBankName: 'Australian Bank Name',
    BankAccountNumber: 'Bank Account Number',
    AccNumber: 'Account Number',
    AccountNumberdigit: 'Account number digit',
    FasaPayAccountNumber: 'FasaPay Account Number',
    BankAccountBeneficiary: 'Bank Account Beneficiary',
    BSB: 'BSB',
    SortCode: 'Sort Code',
    skrillEmail: 'Skrill Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    BitwalletEmail: 'Bitwallet Email',
    SticpayEmail: 'SticPay Email',
    BitcoinEmail: 'Bitcoin Wallet Address',
    USDTEmail: 'Tether Wallet Address',
    USDCEmail: 'USDC (ERC-20) Wallet Address',
    EthEmail: 'ETH Wallet Address',
    USDTChain: 'Chain',
    BankBranch: 'Bank Branch (3-digits branch number)',
    Province: 'Province',
    City: 'City',
    BankAddress: 'Bank Address',
    AccountNumberIBAN: 'Account Number/IBAN',
    AccountNumber: 'Account Number',
    BankBeneficiaryName: 'Bank Beneficiary Name',
    Fullname: 'Full Name',
    BankAccountBeneficiaryName: 'Bank Account Beneficiary Name',
    BankAccountName: 'Bank Account Name',
    AccountHoldersAddress: "Account Holder's Address",
    SelectWithdrawCard: 'Select Your Withdraw Card',
    Swift: 'Swift',
    SwiftAud: 'Swift(Non AUD Accounts)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    DocumentNumber: 'Document Number',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    BankAccountType: 'Bank Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Important Notes',
    ifscCode: 'IFSC Code',
    uploadDocument: 'Please upload a copy of your bank statement (current to the last 3 months)',
    name: 'Name',
    phone: 'Phone',
    address: 'Address',
    email: 'Email',
    vpa: 'VPA',
    YourPaymentGreaterThanAvailableBalance: 'Your payment is greater than the available balance',
    integer: 'Only integer input is supported',
    plnWithdraw: 'Amount must be less than zł {money} PLN',
    CannotUseMoreThanTwoDecimalValues: 'You cannot use Numbers and negative Numbers with more than two decimal places',
    YourPaymentIsBeingProcessed: 'Your withdraw application has been submitted',
    Itcannotbe0: "It can't be 0",
    noCardWarn: 'You don’t have any available card to make withdrawal, please link your card in Withdraw Details first',
    amtLarger: 'The minimum withdrawal amount is {currencyType}{minLimit}. Balances under 30 must be withdrawn in full',
    blackList:
      'Unfortunately you are currently unable to submit a withdrawal request. Please contact {supportEmail} for more information',
    NetellerChargeAlert:
      'Neteller withdrawals are limited to $500,000 per transaction, and a 2% fee (capped at $30) applies.',
    FasaPayChargeAlert: 'FasaPay charges 0.5% transaction fee for every withdrawal.',
    SkrillChargeAlert: 'Skrill charges 1% transaction fee for every withdrawal.',
    cryptoAlert:
      'Please note that we are unable to deposit or withdraw via BUSDT, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund.',
    usdtNote:
      'The current gas fee of ERC20 is significantly higher than usual and as a result there may be a lengthy delay in the processing of your transaction. Alternatively, you are able to withdraw funds via TRC20 by providing the coresponding wallet address, or you can elect to pay the gas fee to speed up your ERC20 withdrawal.',
    status: {
      withdrawalSubmitted: 'Submitted',
      withdrawalCanceled: 'Cancelled',
      withdrawalAuditing: 'Processing',
      withdrawalRejected: 'Rejected',
      withdrawalAccepted: 'Processing',
      withdrawalPayFailed: 'Failed',
      withdrawalSuccess: 'Processed',
      withdrawalFailed: 'Failed',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Failed',
      transferSubmitted: 'Processing',
      transferSuccess: 'Processed',
      transferRejected: 'Rejected',
      transferFailed: 'Failed',
      transferProcessing: 'Processing',
    },
    formErrorMsg: {
      bankCodeReq: 'Bank Code is required',
      bankBranchCodeReq: 'Branch code is required',
      bankName: 'Bank Name is required',
      bankAccType: 'Account Type is required',
      branchCode: 'Branch Code is required',
      bsb: 'BSB is required',
      sortCode: 'Sort Code is required',
      beneName: 'Bank Beneficiary Name is required',
      accName: 'Bank Account Name is required',
      accNum: 'Bank Account Number is required',
      accReq: 'Account Number is required',
      swift: 'Swift is required',
      bankAddress: 'Bank address is required',
      holderAddress: 'Holder address is required',
      amt: 'Amount is required',
      firstSixDigits: 'Please Enter First 6 digits',
      lastFourDigits: 'Please Enter Last 4 digits',
      branchName: 'Branch name is required',
      email: 'Email is required',
      accName: 'Account name is required',
      province: 'Bank province is required',
      city: 'Bank City is required',
      bankCard: 'Please select a card',
      emailFormat: 'Please input correct email address',
      amt0: 'Amount cannot be 0',
      ifscCode: 'IFSC Code is required',
      bitcoin: 'Bitcoin Wallet Address is required',
      bitcoinFormat: 'Incorrect Bitcoin wallet address format',
      bitcoinAddressLength: 'Bitcoin wallet address must be between 26 and 42 characters',
      usdt: 'USDT Wallet Address is required',
      usdc: 'USDC (ERC-20) Wallet Address is required',
      eth: 'ETH Wallet Address is required',
      chain: 'Chain is required',
      walletFormatIncorrect: 'Incorrect {type} wallet address format',
      dynamicReq: '{fieldname} is required',
      uploadDocument: 'Please upload a bank statement',
      spaceReq: 'No space is allowed.',
      alphReq: 'Only alphanumeric characters are allowed',
      onlyChineseCharcters: 'Please input Chinese characters only',
      invalidCode: 'Code is required',
    },
    note: "Due to recent changes, our bank may be unable to process international bank transfers for clients registered in some countries.<br/>If you must withdraw via international bank transfers, please contact our Customer Support on +44 2080 363 883 or at <a href='mailto:{mail}'>{mail}</a>.<br/>\n          We apologise for the inconveniences.",
    minAmountWarn:
      'The applied withdrawal amount is less than the minimum for the applied payment gateway. Please top up your account balance to/over the minimum before submitting a withdrawal request. More information can be found <a href="https://{regulatorUrl}/clients/accounts/withdraw/">here</a>.',
    minAmountWarnNoLink:
      'The applied withdrawal amount is less than the minimum for the applied payment gateway. Please top up your account balance to/over the minimum before submitting a withdrawal request.',
    negativeBalance:
      'As your other account(s) {accounts} currently has negative balance, we cannot accept your withdrawal request. Please top up the negative balance or contact your account manager to write off your account balance.',
    warning: {
      desc: 'Please be aware of the following:',
      desc1: 'The end-user will incur a bank transfer fee, which typically ranges from 135 to 250 JPY.',
      desc2: 'Withdrawal requests made after 2 PM may be delayed or processed the following day.',
    },
  },
  rebate: { status: { processed: 'Processed', processing: 'Processing', rejected: 'Rejected' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'REBATE TRANSFER REQUEST',
    transferAmountIsGreaterThanAvailableBalance: 'Your transfer amount is greater than the available balance',
    yourRebateTransferHasBeenProcessed: 'Your rebate transfer has been submitted',
    PleaseEnterTransferInformation: 'Please enter accurate transfer information',
    tradingAccount: 'Trading Account',
    rebateAccount: 'Rebate Account',
    account: 'Account',
    blackList:
      'This account has been restricted from making a transfer between accounts, please contact {supportEmail}',
  },
  transferHistory: {
    payStatus: { processing: 'Processing', paid: 'Paid', unpaid: 'Unpaid', rejected: 'Rejected' },
  },
  common: {
    selectIb: 'Select IB',
    liveChat: { desc: '<li><p>Need Help?</p><span>Live Chat</span></li>' },
    lang: {
      english: 'English',
      chinese: 'Chinese',
      french: 'French',
      thai: 'Thai',
      german: 'German',
      spanish: 'Spanish',
      malay: 'Malay',
      viet: 'Vietnamese',
      indo: 'Indonesian',
      arabic: 'Arabic',
      japanese: 'Japanese',
      mongolia: 'Mongolia',
      portuguese: 'Portuguese',
    },
    button: {
      confirm: 'CONFIRM',
      cancel: 'CANCEL',
      identityProof: 'COMPLETE APPLICATION',
      upload: 'UPLOAD',
      selectFile: 'Select File',
      fundnow: 'FUND NOW',
      iunderstand: 'I understand',
    },
    withdrawChannel: {
      internetbankingvietnam: 'Internet Banking  (Vietnam)',
      internetbankingsouthafrica: 'Internet Banking (South Africa) ',
      internetbankingIndonesia: 'Internet Banking (Indonesia) ',
      internetbankingPhilippine: 'Internet Banking (Philippine) ',
      internetbankingLaos: 'Internet Banking (Laos) ',
      internetbankingThailand: 'Internet Banking (Thailand) ',
      internetbankingMalaysia: 'Internet Banking (Malaysia) ',
      localdepositor: 'Local Depositor',
      perubanktransfer: 'Peru Instant Bank Transfer',
      colombiabanktransfer: 'Colombia Instant Bank Transfer',
      ecuadorbanktransfer: 'Ecuador Instant Bank Transfer',
      chilebanktransfer: 'Chile Instant Bank Transfer',
      mexicobanktransfer: 'Mexico Instant Bank Transfer',
      brazilbanktransfer: 'Brazil Instant Bank Transfer',
      ebuy: 'Ebuy',
      pluswallets: 'Plus Wallets',
      beeteller: 'Beeteller',
      withdrawalServiceCharge_ibt:
        'For withdrawals via Bank wire, INFINOX charges a fee of 15 USD. And an overseas remittance fee may be charged from the used bank.',
      withdrawalServiceCharge_chanel:
        'Withdrawals over 50 USD will incur no charge, less than 50 USD will incur a fee of 15 USD.',
      withdrawalBankMsg: 'Please be consistent with the bank selected when depositing',
      internetbankingperu: 'Internet Banking (Peru)',
      internetbankingcolumbia: 'Internet Banking (Columbia)',
      internetbankingcolombia: 'Internet Banking (Colombia)',
      internetbankingchile: 'Internet Banking (Chile)',
      internetbankingecuador: 'Internet Banking (Ecuador)',
      internetbankingcambodia: 'Internet Banking (Cambodia)',
      internetbankingmyanmar: 'Internet Banking (Myanmar)',
      internetbankingkorea: 'Internet Banking (South Korea)',
      dragonphoenix: 'Internet Banking (South Korea)',
      cryptocurrencyethcps: 'Cryptocurrency-ETH',
      cryptocurrencyeth: 'Cryptocurrency-ETH',
      cryptocurrencyusdc: 'Cryptocurrency-USDC',
      cryptocurrencyusdccps: 'Cryptocurrency-USDC',
      cryptocurrencybtc: 'Cryptocurrency-BTC',
      cryptocurrencyusdterc20: 'Cryptocurrency-USDT(ERC-20)',
      cryptocurrencyusdttrc20: 'Cryptocurrency-USDT(TRC-20)',
      malaysiabanktransfer: 'Malaysia Instant Bank Transfer',
      indonesiabanktransfer: 'Indonesia Instant  Bank Transfer',
      vietnambanktransfer: 'Vietnam Instant  Bank Transfer',
      philippinesbanktransfer: 'Philippines Instant  Bank Transfer',
      thailandbanktransfer: 'Thailand Instant  Bank Transfer',
      cambodiabanktransfer: 'Cambodia Instant Bank Transfer',
      laosbanktransfer: 'Laos Instant Bank  Transfer',
      myanmarbanktransfer: ' Myanmar Instant Bank  Transfer',
      southafricabanktransfer: ' South Africa Instant Bank  Transfer',
      southKoreabanktransfer: ' South Korea Instant Bank  Transfer',
      chinabanktransfer: ' China Instant Bank Transfer',
      hongkongbanktransfer: 'Hong Kong Local Bank Transfer',
      banktransfer: 'Bank Transfer',
      aus: 'Bank Transfer - Australia',
      int: 'Bank Transfer - International',
      chn: 'Bank Transfer - China',
      uk: 'Bank Transfer - UK',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Thailand Instant Bank Wire Transfer',
      malaysiainstantbankwiretransfer: 'Malaysia Instant Bank Transfer',
      banktransferbpaypolipay: 'Bank Transfer/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptocurrency-BTC',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      nigeriabanktransfer: 'Nigeria Instant Bank Transfer',
      nigeriainstantbankwiretransfer: 'Internet Banking (Nigeria)',
      vietnaminstantbankwiretransfer: 'Vietnam Instant Bank Transfer',
      indonesiainstantbankwiretransfer: 'Indonesia Instant Bank Transfer',
      philippinesinstantbankwiretransfer: 'Philippine instant bank wire transfer',
      southafricainstantbankwiretransfer: 'South Africa instant bank wire transfer',
      indiainstantbanktransfer: 'India Instant Bank Wire Transfer',
      laosinstantbankwiretransfer: 'Laos instant bank wire transfer',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Transfer to accounts',
      dasshpeupi: 'UPI',
      perfectmoney: 'Perfect Money',
      ugandabanktransfer: 'Uganda Local Bank Transfer',
      rwandabanktransfer: 'Rwanda Local Bank Transfer',
      zambiabanktransfer: 'Zambia Local Bank Transfer',
      congobanktransfer: 'Congo Local Bank Transfer',
      cameroonbanktransfer: 'Cameroon Local Bank Transfer',
      burundibanktransfer: 'Burundi Local Bank Transfer',
      kenyabanktransfer: 'Kenya Instant Bank Transfer',
      ghanabanktransfer: 'Ghana Local Bank Transfer',
      ghanamobilemoney: 'Ghana Mobile Money',
      tanzaniabanktransfer: 'Tanzania Local Bank Transfer',
      internetbankingmexico: 'Internet Banking (Mexico)',
      internetbankingbrazil: 'Internet Banking (Brazil)',
      internetbankingchina: 'Internet Banking (China)',
      alipay: 'Alipay',
      praxis: {
        zotapay: {
          japan: 'Japan Bank Transfer',
        },
      },
    },
    history: { kenyabanktransfer: 'Internet Banking (Kenya)' },
    country: { international: 'International', australia: 'Australia', uk: 'United Kingdom' },
    field: { country_region: 'Country/Region', yes: 'Yes', no: 'No' },
    keys: {
      SUBMIT: 'SUBMIT',
      FROM: 'FROM',
      UPDATE: 'UPDATE',
      STATUS: 'STATUS',
      TO: 'TO',
      DATE: 'DATE',
      pleaseSelect: 'Please Select',
      amount: 'Amount',
      amountOut: 'Amount out',
      amountIn: 'Amount in',
      actualAmount: '  Actual withdrawals = ',
      rebateAccount: 'Rebate Account',
      comingSoon: 'coming soon',
      tradingHistory: 'TRADING HISTORY',
      noData: 'No data',
      NAME: 'NAME',
      EMAIL: 'EMAIL',
      MOBILE: 'Mobile',
      ACCTYPE: 'ACCOUNT TYPE',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASE CURRENCY',
      DATERANGE: 'DATE RANGE',
      DEST: 'DESTINATION',
      ACCNUM: 'ACCOUNT NUMBER',
      BALANCE: 'BALANCE',
      NONE: 'None',
      ALL: 'All',
      VIEW: 'View',
      PROFITLOST: 'Profit Lost',
      ACCEQUITY: 'Account Equity',
      MARGINLEVEL: 'Margin Level(%)',
      ACCOUNTEQUITY: 'Account Equity',
      CREDIT: 'Credit',
      DOWNLOAD: 'DOWNLOAD',
    },
    dateRange: {
      today: 'Today',
      yesterday: 'Yesterday',
      lastSevenDays: 'Last 7 Days',
      lastThirtyDays: 'Last 30 Days',
      weekToDate: 'Week To Date',
      monthToDate: 'Month To Date',
      quarterToDate: 'Quarter To Date',
      previousWeek: 'Previous Week',
      previousMonth: 'Previous Month',
      previousQuarter: 'Previous Quarter',
    },
    products: {
      forex: 'FOREX',
      commodities: 'COMMODITIES',
      indices: 'INDICES',
      crypto: 'CRYPTO',
      sharecfd: 'SHARE CFD',
      bond: 'BOND',
      bonds: 'BOND',
      gold: 'GOLD',
      minigold: 'MINI GOLD',
      goldfutures: 'GOLD FUTURES',
      bitcoin: 'BITCOIN',
      silver: 'SILVER',
      silverfutures: 'SILVER FUTURES',
      oil: 'OIL',
      oilfutures: 'OIL FUTURES',
      index: 'INDEX',
      indexfutures: 'INDEX FUTURES',
      etf: 'ETF',
      forexfutures: 'FOREX FUTURES',
      stock: 'STOCK',
    },
    formValidation: {
      onlyChinese: 'Only Chinese is allowed',
      noSymbolAllowed: 'No symbols are allowed',
    },
  },
  withdrawalFreeze: {
    header: 'Withdrawals are temporarily not supported',
    desc: 'Due to the modification of your login password, withdrawals are temporarily not supported for T+{day} days to ensure your account security. This restriction is expected to be lifted in {hour} hours.',
    desc2:
      'Due to the modification of your login email/phone number, withdrawals are temporarily not supported for T+{day} days. This restriction is expected to be lifted in {hour} hours.',
  },
  report: {
    rebate: {
      column: {
        name: 'NAME',
        accountNumber: 'ACCOUNT NUMBER',
        accountType: 'ACCOUNT TYPE',
        volume: 'Volume',
        rebate: 'Rebate',
        totalRebate: 'TOTAL REBATE',
        crypto: 'CRYPTO',
        sharecfd: 'SHARE CFD',
        date: 'DATE',
        instrument: 'INSTRUMENT',
        notionalvalue: 'NOTIONAL VALUE',
      },
      dropdown: { allInstruments: 'All Instruments', all: 'All', instruments: 'Instruments' },
      others: {
        rebate: 'REBATE',
        instrumentsTraded: 'INSTRUMENTS TRADED',
        lots: 'Lots',
        totalRebate: 'Total Rebate',
        rebates: 'Rebates',
        searchBy: 'Search by name/account',
      },
    },
    nodataMessage: 'There is no data filtered. Kindly filter the search criteria before download.',
    timeLimitMessage: 'maximum query range for download is 90 days,please update the new date range',
    trade: {
      column: {
        account: 'Account',
        ticket: 'Ticket',
        platform: 'Platform',
        symbol: 'Symbol',
        type: 'Type',
        closeTime: 'Close Time',
        profit: 'Profit',
        commission: 'Commission',
      },
      download: 'Downloading',
      clear: 'Clear',
      dateRangeLimit: 'Maximum date range is 3 months',
    },
    btn: {
      showAll: 'Show All',
      hide: 'Hide',
    },
  },
  ibReport: { openedAccs: 'OPENED ACCOUNTS', funding: 'FUNDING', account: 'ACCOUNT' },
  lead: { demoAccounts: 'DEMO ACCOUNTS' },
  pendingAccounts: {
    errorDoc:
      'The following applicants have filled in the online application form but are yet to supply proof of identification (ID) in order to finalise their account.',
    acceptedDocType: 'Accepted forms of ID include:',
    primaryDoc: 'Primary ID',
    passport: 'A copy of your valid international passport (signature page)',
    id: 'A copy of your government issued national identity card',
    dl: 'A copy of your valid driver’s licence',
    divc: 'A copy of your  ID voters card',
    dric: 'A copy of your resident ID card',
    address: 'Secondary ID',
    addressList: [
      'A copy of a recent public utility bill. E.g. Gas or electricity that contains your name and residential address (no older than 3 months)',
      'A copy of your credit card statement or bank statement (no online print-offs)',
    ],
    tip: 'Please encourage applicants to provide a copy of ONE of the accepted primary and secondary ID documents to ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'REBATES',
    withdrawHistory: 'WITHDRAWALS',
    transferHistory: 'TRANSFERS',
    cancelWithdraw: 'Would you like to Cancel the Withdrawal Request?',
    cancelError:
      'Your withdrawal is being processed at the moment. Therefore, we cannot accept your cancellation request. For more information, you may reach us using the live chat feature.',
  },
  referralLinks: {
    copyLink: 'COPY LINK',
    downloadPage: 'Download APP',
    campLang: 'Campaign Language',
    selectCountryOrLang: 'Select your client’s country or preferred language',
    byCountry: 'By Country',
    byLanguage: 'By Language',
    china: 'China',
    vietnam: 'Vietnam',
    indonesia: 'Indonesia',
    cpy: 'COPY',
    alreadyCPY: 'COPIED!',
    liveAcc: 'Live Account',
    demoAcc: 'Demo Account',
    homePage: 'Home Page',
    promotion: 'Event promotion link',
    hintTitle: 'What is an event promotion link?',
    hintMessage:
      'The event promotion link is a new promotion method provided to IB. The sharing link will guide users to the bonus event page and complete the registration. The success rate of user registration can be effectively improved by sharing the link.',
    hintShortLink: 'Share using short link',
    hintShortLinkTitle: 'What is a short link?',
    hintShortLinkMessage:
      'Short link is a link generation rule, which can better protect your shared link from being changed by others, thus protecting your IB development rights',
    inviteCodeHeader: 'Promotion Invitation Code',
    inviteCode: 'Invitation code',
    customButton: 'Customize',
    copyButton: 'Copy',
    customInviteCodeHeader: 'Custom invitation code',
    currentCode: 'Current invitation code',
    newCode: 'New invitation code',
    notice: 'Note: The invitation code has at least 6 parameters. Letters, numbers, non-special symbols can be entered',
    inviteCodeFormatError: 'The format of the invitation code is wrong, please edit it again',
    inviteCodeAlreadyExist: 'The invitation code has been preemptively registered by others, please re-enter',
    pleaseEnter: 'Please enter',
  },
  settings: {
    info: 'INFO',
    reg: 'REGISTERED ON',
    CustomerInfo: 'Customer Information',
    name: 'Name',
    email: 'Email',
    phone: 'Telephone number',
    address: 'Address',
  },
  unfundedAcc: {
    neverFundedAccs: 'NEVER FUNDED ACCOUNTS',
    prevFundedZero: 'PREVIOUSLY FUNDED (ZERO BALANCE)',
    accBalance: 'ACCOUNT BALANCE',
  },
  multiib: { level: 'LEVEL {level}', noib: 'There are no sub-IBs under this account.' },
  AWCDialog: { optIn: 'OPT-IN NOW', noThanks: 'NO THANKS' },
  header: {
    bckToCP: 'BACK TO CLIENT PORTAL',
    logout: 'LOG OUT',
    chooselang: 'Choose your language:',
    welcome: 'Welcome back',
  },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'IB REPORT',
    rebateReport: 'REBATE REPORT',
    iblevel: 'MULTI-LEVEL IB',
    ibAccs: 'IB ACCOUNTS',
    leads: 'LEADS',
    pendingAccs: 'PENDING ACCOUNTS',
    unfundedAccs: 'UNFUNDED ACCOUNTS',
    allRetailAccs: 'All RETAIL ACCOUNTS',
    payHistory: 'TRANSACTION HISTORY',
    ibProfile: 'IB PROFILE',
    refLinks: 'REFERRAL LINKS',
    contactUs: 'CONTACT US',
    switchCP: 'SWITCH TO CLIENT PORTAL',
    tradeReport: 'TRADE REPORT',
  },
  footer: {
    riskWarn1: 'Risk warning: CFDs are leveraged products and involve risk. You could lose your entire deposit.',
    riskWarn_fsc:
      'Risk warning: Derivatives are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how Derivatives work and whether you can afford to take the high risk of losing your money.',
  },
  allRetailAccs: {
    type: {
      all: 'All',
      zero: 'Zero Balance',
      funded: 'Funded',
      unfunded: 'Unfunded',
      deposit: 'Deposit',
      withdrawal: 'Withdrawal',
    },
    filter: { name: 'Name', email: 'Email', account: 'Account' },
    placeholder: 'Search email/name/account/mobile',
    search: 'Search',
    tradeHistory: 'Trade History',
    depwith: 'Deposit & Withdrawal',
    summary: 'Summary',
    currency: 'Currency',
    totalDeposit: 'Total Deposit',
    totalWithdrawal: 'Total Withdrawal',
    netDeposit: 'Net Deposit',
  },
  responseMsg: {
    410: 'Parameter validation failed',
    411: 'Incorrect password',
    420: 'User does not exist',
    421: 'Password reset denied',
    490: 'Please do not submit duplicate card information',
    500: 'An error has occured.',
    501: 'Application failed. Please try again later.',
    505: 'File upload failed',
    510: 'Incorrect SMS code',
    511: 'Unable to send SMS code to your phone',
    520: 'Login Failed. Please try again.',
    521: 'A user is already logged in on this device!',
    522: 'Please log in again',
    523: 'Please log in again',
    524: 'Unfortunately your email address was not recognized',
    525: 'Incorrect username or password',
    526: 'Recaptcha does not matchs',
    527: 'User is not an IB',
    528: 'User does not exist',
    529: 'Incorrect username or password',
    530: 'Code invalid',
    531: 'Code invalid',
    540: "Your trading account couldn't be found",
    541: 'Cannot find rebate account',
    542: 'You are not allowed to open additional account',
    544: 'You are not allowed to apply for an additional account until your Proof of ID verified',
    550: 'Incorrect account number',
    551: 'Your balance is insufficient',
    554: 'Requested amount is zero or null',
    562: 'We were unable to process your payment, please try again. If the problem persists, please contact us via live chat, or email {email}',
    564: 'Country does not match the payment channel',
    565: 'Incorrect Bank Code',
    566: 'Payment method not found',
    567: 'Unfortunately this payment method is currently unavailable. We apologise for any inconvenience caused',
    581: 'You have successfully opted in to the promotion. Please note that you must make a deposit in order to withdraw funds.',
    584: "You don't have any applicable rebates",
    590: 'Cannot find transfer-out account',
    591: 'Cannot find transfer-in account',
    593: 'We are unable to process your funds transfer from the account selected due to your account containing credit.',
    594: 'Transfer of funds from this account is not allowed',
    session_timeout: 'Session timeout, redirecting to login page.',
  },
}
